import styles from './index.module.css'
import { FaWhatsapp } from 'react-icons/fa'


function Footer() {
    return <footer className={styles.footer}>
        <p onClick={() => window.open("https://api.whatsapp.com/send?phone=5527999928105")} className={styles.contact}><FaWhatsapp />+55 27 99992 8105</p>
        <p className={styles.copy_right}><span>my-app</span> &copy; 2024</p>
    </footer>
}

export default Footer