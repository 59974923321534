import savings from '../../img/image.webp'
import styles from './index.module.css'

function Home() {
    return <section className={styles.home_content}>
        <h1>
            Bem-vindo ao <span>my-app</span>
        </h1>
        <p>Transforme sua empresa com nossa solução completa: Aplicativo e site personalizados, a chave para revitalizar processos e impulsionar o sucesso do seu negócio!</p>
        <a href="https://api.whatsapp.com/send?phone=5527999928105">Entrar em contato</a>
        <img src={savings} alt='svg' />
    </section>
}

export default Home