import { Link } from "react-router-dom";
import Container from "../Container";
import logo from '../../img/logo.png'
import styles from './index.module.css'

function Header() {
    return <nav className={styles.navBar}>
        <Container>
            <Link to='/'><img src={logo} alt='logo'></img></Link>
        </Container>
    </nav>
}

export default Header